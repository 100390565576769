<template>
  <div>
    <div class="zuvulguu_medeelel">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="zuvulguu_medeelel_title"><span class="yelow">{{ 'advice' | translate }}</span> {{ 'information' | translate }}
              <router-link to="/advice_information_list" class="read_more"><img src="../../assets/all_view.png" alt="">  {{ 'bugdiig_harah' | translate }}</router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="zuvulguu_medeelel_slide">
              <div class="sub-menu">
                <ul>
                  <li :key="index" v-for="(zovolgoo_angilal, index) in ds_zovolgoo_angilal_en_view">
<!--                    :class="[(activeItem == zovolgoo_angilal.id) ? 'visible':'']"-->
                    <a href="javascript:void(0);" class="item"
                       :class="[index+1===activeItem ? 'visible' : '']"
                       @click="callNewsData(zovolgoo_angilal.id)"  >
                      <span v-if="language.short == 'mn'">{{ zovolgoo_angilal.z_angilal_mn }}</span>
                      <span v-else>{{ zovolgoo_angilal.z_angilal_en }}</span>
                    </a>
                  </li>
                </ul>
              </div>
<!--              <div v-if="loading" class="loading-wrap">-->
<!--                <div class="lds-ellipsis">-->
<!--                  <div></div>-->
<!--                  <div></div>-->
<!--                  <div></div>-->
<!--                  <div></div>-->
<!--                </div>-->
<!--              </div>-->
              <swiper class="swiper" :options="medee_medeelel_slide">
                <swiper-slide v-show="index <= 4" :key="index" v-for="(news, index) in news_datas">
                  <div class="slide" >
                    <router-link  :to="`/advice_information_details/${news.id}`">
                      <div class="view view-first">
                        <img :src="IMAGE_URL + news.zurag" alt="">
                      </div>
                      <div class="repost-text">
                        <router-link :to="`/advice_information_details/${news.id}`">
                          <h4 v-if="language.short == 'mn'"> {{news.zovolgoo_mn}} </h4>
                          <h4 v-else> {{news.zovolgoo_en}} </h4>
                        </router-link>
                      </div>
                      <ul class="post-tags clearfix">
                        <li>{{ getDate(news.created_at) }}</li>
                        <li class="rigth">{{ 'detail' | translate }} <i class="ivu-icon ivu-icon-md-return-right"></i></li>
                      </ul>
                    </router-link>
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {GET_ZUWULGUU_MEDEE_ANGILAL,GET_ZUWULGUU_MEDEE_BY_CATEGORY_ID, IMAGE_URL} from "../../graphql/queries";
import {mapGetters} from "vuex";

export default {

  name: "standart",
  components: {

  },
  data () {
    return {
      loading: true,
      activeItem: 0,
      IMAGE_URL: IMAGE_URL,
      ds_zovolgoo_angilal_en_view: [],
      news_datas: [],
      medee_medeelel_slide: {
        slidesPerView: 4,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          414: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          375: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 15
          }
        }
      },
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    this.getGet_zuwulguu_news_category();
    this.callNewsData()
  },
  methods: {
    getDate(datetime) {
      let date = new Date(datetime).toJSON().slice(0, 10).replace(/-/g, '.')
      return date
    },
    getGet_zuwulguu_news_category() {
      this.$apollo.query({query: GET_ZUWULGUU_MEDEE_ANGILAL}).then(({data}) => {
        this.ds_zovolgoo_angilal_en_view = data.ds_zovolgoo_angilal_en_view;
      })
    },
    callNewsData(cat_id){
      this.currentCat = cat_id;
      this.activeItem = cat_id;
      this.loading = true;
      if (this.currentCat) {
        this.$apollo.query({query: GET_ZUWULGUU_MEDEE_BY_CATEGORY_ID, variables: {cat_id: this.currentCat.toString()}}).then(({data}) => {
          this.news_datas = data.ds_zov_medeelel_view;
          setTimeout(() => {
            this.loading = false;
          }, 1000)
        });
      }else{
        this.activeItem = 1;
        this.$apollo.query({query: GET_ZUWULGUU_MEDEE_BY_CATEGORY_ID, variables: {cat_id: this.activeItem.toString()}}).then(({data}) => {
          this.news_datas = data.ds_zov_medeelel_view;
          setTimeout(() => {
            this.loading = false;
          }, 1000)
        });
      }
    }
  },

}




</script>
